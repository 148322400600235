import './SignIn.scss';
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useRef } from "react";


const SignIn = () => {
    const emailRef = useRef(null);
    const passRef = useRef(null);

    const login = (e) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth,
            emailRef.current.value,
            passRef.current.value
        ).then((authUser) => {
            console.log(authUser)

        }).catch((err) => {
            alert(err.message);
        })
    }

    return (
        <div className="sign-in">
            <h1>Zaloguj się</h1>
            <label htmlFor="email"></label>
            <input type="text" placeholder="podaj email" name="email" ref={emailRef}/>
            <label htmlFor="pass"></label>
            <input type="password" placeholder="podaj hasło" name="pass" ref={passRef}/>
            <input type="submit" onClick={login}/>
        </div>

    )
}

export default SignIn;