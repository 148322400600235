import './SignUp.scss';
import React, { useRef } from "react";
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";


const SignIn = () => {
    const emailRef = useRef(null);
    const passRef = useRef(null);
    const nameRef = useRef(null);

    const register = (e) => {
        e.preventDefault();

        createUserWithEmailAndPassword(auth,
            emailRef.current.value,
            passRef.current.value,
          

        ).then((authUser) => {
            return authUser.user.updateProfile({
                displayName: nameRef.current.value,
            });
           


        }).catch((error) => {
            alert(error.message);
        })
     
    }

    // const logout = () => {
    //     auth.signOut();
    //     dispatch(logout);

    // }

    return (
        <div className="sign-up">
            <h1>Zarejestruj się</h1>
            <label htmlFor="fname"></label>
            <input type="text" placeholder="podaj imię" name="fname" ref={nameRef}/>
            <label htmlFor="sname"></label>
            <input type="text" placeholder="podaj nazwisko" name="sname"/>
            <label htmlFor="email"></label>
            <input type="text" placeholder="podaj email" name="email" ref={emailRef}/>
            <label htmlFor="pass"></label>
            <input type="password" placeholder="podaj hasło" name="pass"ref={passRef}/>
            <input type="submit" onClick={register}/>
            {/* <button onClick={logout}></button> */}
        </div>

    )
}

export default SignIn;