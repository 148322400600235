import React from 'react';
import { auth } from '../../firebase';
import { useDispatch } from 'react-redux';

import { Navigate } from 'react-router-dom';

function Account() {

    const logout = ()=> {
        auth.signOut();

        <Navigate to="/" replace="true"/>

    }

  return (
    <>
    <div className="profile">
        <h1>Witaj, {}</h1>

    </div>
    <button onClick={logout}>Logout</button>
    </>
  )
}

export default Account