import arrow from '../blobs/blob-reviews-arrow.svg'
import glowUpImg from '../img/glowUp.png'
import Glowup from '../components/Glowup'
import "./GlowUpsPage.scss";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import glowupsData from'../data/glowupsData.js';
import cloudBlob from '../blobs/blob-glowup.svg';

const GlowUpsPage = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isScrolling, setIsScrolling] = useState(false);
    const [showLeftScrollButton, setShowLeftScrollButton] = useState(false);
    const [showRightScrollButton, setShowRightScrollButton] = useState(true);
    const scrollContainerRef = useRef(null);
    const title1 = useRef(null);
    const line2 = useRef(null);
    const alan3 = useRef(null);
    const glowupRef = useRef(null);

    const glowupWidth = ()=> {
        const computedStyles = window.getComputedStyle(glowupRef.current);
        const totalWidth =
        parseFloat(computedStyles.width) +
        parseFloat(computedStyles.paddingLeft) +
        parseFloat(computedStyles.paddingRight) 
        return totalWidth;
    }

    const handleScroll = () => {
        const container = scrollContainerRef.current;
      
        if (container) {
          if (container.scrollLeft <= 50) {
            setShowLeftScrollButton(false);
          } else {
            setShowLeftScrollButton(true);
          }
      
          if (
            container.scrollLeft >=
            container.scrollWidth - container.clientWidth - 50
          ) {
            setShowRightScrollButton(false);
          } else {
            setShowRightScrollButton(true);
          }
        }
      };

    useEffect(()=> {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
      
    },[windowWidth ]);

    const ArrowLeft = ({onClick}) => {
        return (
            <button className="left-scroll"onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
            </button>
        )
    }

    const ArrowRight = ({onClick}) => {
        return (
           <button className="right-scroll"onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
           </button>
        )
    }

    const handleScrollLeft = () => {
        if (!isScrolling) {
            setIsScrolling(true);
            const scrollDistance = windowWidth >= glowupWidth() ? -windowWidth : -windowWidth;

            scrollContainerRef.current.scrollBy({
                left: scrollDistance,
                behavior: 'smooth',
            });

            
            setTimeout(() => {
                setIsScrolling(false);
            }, 500); 
        }
    };

    const handleScrollRight = () => {
        if (!isScrolling) {
            setIsScrolling(true);
             const scrollDistance = windowWidth >= glowupWidth() ? windowWidth : windowWidth;
            

            scrollContainerRef.current.scrollBy({
                left: scrollDistance,
                behavior: 'smooth',
            });

          
            setTimeout(() => {
                setIsScrolling(false);
            }, 500);
        }
    };

      useLayoutEffect(() => {
          
      }, []);
      
    return (
        <section className="glowups-page" id="glowups-page">
            <div className="glowups-page__header padding100gap100" style={{
                overflow: 'visible'
            }} >
                <div className="glowups-page__header__content">
                    <div className="main-titles" ref={title1}>
                        <h1 className="main-titles__title">
                          Przemiany
                        </h1>
                        <h2 className="main-titles__subtitle">
                           zobacz przemiany moich podopiecznych
                        </h2>
                        
                    </div><img className='dottedArrow dottedArrow--centered' src={arrow} alt="arrow" ref={line2}/>
                </div>
                <img src={glowUpImg} alt="" className="alan alanImg" ref={alan3}/>
            </div>
            <div className="glowups-container" ref={scrollContainerRef} onScroll={handleScroll}
           >

            {showLeftScrollButton && (
            <ArrowLeft onClick={handleScrollLeft}></ArrowLeft>
            )}
             
                {glowupsData.map((glowup, index) => {
                    return (
                        <div className="glowup-wrapper" style={{ 
                            backgroundImage: `url(${cloudBlob})`
                        }}>
                        <Glowup {...glowup} key={index} ref={glowupRef}   /></div>
                    )
                })}
                
            {showRightScrollButton && (
            <ArrowRight onClick={handleScrollRight}></ArrowRight>
            )}         
            </div>
        </section>
    );
};

export default GlowUpsPage;
