import React from 'react';
import './ProductBlock.scss';
import FrontTshirt from '../img/products/tshirts/koszulka_przod.jpg';
import BackTshirt from '../img/products/tshirts/koszulka_tyl.jpg';

import FrontWhiteTshirt from '../img/products/tshirts/koszulka_biala_przod.png';
import BackWhiteTshirt from '../img/products/tshirts/koszulka_biala_tyl.png';
import SideWhiteTshirt from '../img/products/tshirts/koszulka_biala_bok.png';
import './PromoBlock.scss';
import { useState } from 'react';
import BuyBlock from './BuyBlock';
import PromoBlock from './PromoBlock';
import PromoBlockWorkout from './PromoBlockWorkout';
import ProductOptions from './ProductOptions';

import offerData from '../data/offerData';

import Workout1 from '../img/products/workouts/alan_guma.png';
import Workout2 from '../img/products/workouts/trening2.png';

function ProductBlock({title, price, adventages, subtitle, desc, info, isWithPromo, previousPrice }) {


    const [currentPic, setCurrentPic] = useState(FrontTshirt);


    const changeColor = (color) => {


        if (color === 'Biały') {
            setCurrentPic(FrontWhiteTshirt);
        } else if (color === 'Czarny') {
            setCurrentPic(FrontTshirt);
        }
    }


   

  




    const renderPromo = () => {

        return (
            <div className="promo-block">
                 <div className="row">
                <div className="pricing">
                    <div className="pricing__discount-price"> 
                    <span>PLN</span>120<span className="pricing__recurring">/trening</span> </div>
                </div>

                <div className="info-block">
                    <p>Oszczędzasz 50<span className="currency" >PLN</span> </p>
                    <div className='percentage'>9%</div>
                    <p> <span className="currency">PLN</span>
                       550<span className="duration">/5 treningów</span>
                    </p>
                </div>
           </div>
            </div>
        )
    }

  return (
    <div className="product-block">
        <div  className="product-block__img" >

            <img src={(price !==120 && price !==149) ? currentPic : Workout1} alt="koszulka/trening" className="current" />

            {(price !==120 && price !==149) &&  <div className="product-block__slider" >
                <img src={FrontTshirt} onClick={() =>setCurrentPic(FrontTshirt)} alt="koszulka przód" style={{
                    opacity: ((currentPic === FrontTshirt) || (currentPic === Workout1))? 1 : 0.5
                
                }}/>
                <img src={BackTshirt} onClick={() =>setCurrentPic(BackTshirt)} alt="koszulka tył" style={{
                    opacity: ((currentPic === BackTshirt) || (currentPic === Workout2))? 1 : 0.5
                
                }}/>
                 <img src={FrontWhiteTshirt} onClick={() =>setCurrentPic(FrontWhiteTshirt)} alt="koszulka tył" style={{
                    opacity: ((currentPic === FrontWhiteTshirt) )? 1 : 0.5
                
                }}/>
                 <img src={SideWhiteTshirt} onClick={() =>setCurrentPic(SideWhiteTshirt)} alt="koszulka tył" style={{
                    opacity: ((currentPic === SideWhiteTshirt) )? 1 : 0.5
                
                }}/>
                 <img src={BackWhiteTshirt} onClick={() =>setCurrentPic(BackWhiteTshirt)} alt="koszulka tył" style={{
                    opacity: ((currentPic === BackWhiteTshirt) )? 1 : 0.5
                
                }}/>
              
            </div>}

           
        </div>
        <div className="product-block__title">
            <h1>{title}</h1>
            <h2>{subtitle}</h2>

           {(price !== 120 && price !==149) && <ProductOptions options={offerData.oneTime[2].options} changeColor={changeColor}></ProductOptions>}
         
        

            {!isWithPromo &&   <BuyBlock reccuring={false} normalPrice={previousPrice} discountPrice={price} color={"#C464FF"}></BuyBlock>}

            {/* <div className="promo-block">
                 <div className="row">
                <div className="pricing">
                    <div className="pricing__discount-price"> 
                    <span>PLN</span>550<span className="pricing__recurring">/5 treningów</span> </div>
                </div>

                <div className="info-block">
                    <p>Oszczędzasz 50<span className="currency" >PLN</span> </p>
                    <div className='percentage'>9%</div>
                    <p> <span className="currency">PLN</span>
                       550<span className="duration">/5 treningów</span>
                    </p>
                </div>
           </div>
            </div> */}

            {/* <PromoBlockWorkout></PromoBlockWorkout> */}

        </div>
    </div>
   
  )
}

export default ProductBlock;