import alanImg from '../img/homepage2.png';
import ScrollDown from '../components/ScrollDown';
import { useEffect, useRef } from 'react';
import  { useAnimation } from '../components/technical/Context';
import facebookIcon from '../img/facebook-icon.svg';
import instagramIcon from '../img/instagram-icon.svg';
import tiktokIcon from '../img/tiktok-icon.svg';
import { Link } from 'react-router-dom';

import './HomePage.scss'

const HomePage = () => {
  const first = useRef(null);
  const second = useRef(null);

  const onEnter = {
        opacity:1,
        left: "5%"
  };

  const onLeave = {
        opacity:0,
        left: "-20%"
  }
  
  const animation = useAnimation();

  useEffect(() => {
    animation(first.current, onEnter, onLeave);
    animation(second.current, 
    {opacity:1},
    {opacity:0});
  });

  return (
    <>
      <section id="home-page" className="home-page">
        <div className="home-page__text" ref={first}>
          <h1>
            <span>ALAN</span> <br></br> SŁOMIŃSKI <br /> <h3>Trener Personalny</h3>
          </h1>

         <a href="#offer-page"><button className="btn">DOŁĄCZ TERAZ</button></a>
        </div>
        <img className="home-page__image" src={alanImg} alt="" ref={second} />
        <div className="home-page__social-media">
          <a href="https://pl-pl.facebook.com/people/Alan-S%C5%82omi%C5%84ski/pfbid0Z4UL74YQAnTUctufD5dPaFq9X2X8TQ9DA9Lyr3hEwPSFcEABar2kk61ALQjinDZFl/" target="_blank">
            <img src={facebookIcon} alt="facebook-icon"/>
          </a>
          <a href="https://www.instagram.com/alanik_trener/" target="_blank">
            <img src={instagramIcon} alt="instagram-icon" />
          </a>
          <a href="">
            <img src={tiktokIcon} alt="tiktok-icon" />
          </a>
        </div>
      </section>
      <ScrollDown />
    </>
  );
};

export default HomePage;
