import { useState } from "react";
import offertsTrue from "../icons/offer-true.svg";
import offertsFalse from "../icons/offer-false.svg";
import "./OfferBlock.scss";
import BuyBlock from "./BuyBlock";
import CloseBtn from "./CloseBtn";

const OfferBlock = ({title, subtitle, price,  adventages, disatventages, discountPrice, textColor, text, sixMPrice, threeMPrice }) =>{

    const [flipped, setFlipped] = useState(false);



    const variant = title.toLowerCase();

    const lightenColor = (hexColor, amount) => {
        const color = hexColor.replace("#", ""); // Remove the '#' from the color code
        const num = parseInt(color, 16); // Convert the color to a decimal value
      
        // Calculate the lightened color
        const r = Math.round((((num >> 16) & 255) * (1 - amount)) + (amount * 255));
        const g = Math.round((((num >> 8) & 255) * (1 - amount)) + (amount * 255));
        const b = Math.round(((num & 255) * (1 - amount)) + (amount * 255));
      
        // Convert the lightened color back to hexadecimal
        const lightenedColor = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, "0")}`;
      
        return lightenedColor;
      };

    const handleClick = () => {
        setFlipped(!flipped);
    }

    const renderFront = () => {
        return (
                <div className={`offer-page__offerts__content__block ${variant}`} style={{ 
                    backgroundColor: `rgba(${textColor}, 0.1)`,
                }}>
                        <h1 className="offer-page__offerts__content__block__title">
                            {title}
                        </h1>
                        <h2 className="offer-page__offerts__content__block__subtitle">
                            {subtitle}
                        </h2>
                        <h3 className="offer-page__offerts__content__block__price">
                            <span className="currency">PLN</span>{discountPrice}<span className="cycle">/MIES</span>
                        </h3>
                        <button className="offer-page__offerts__content__block__start-now 
                        offer-page__offerts__content__block__start-now--silver" onClick={handleClick} style={{
                            color: `${lightenColor(textColor, 0.15)} `,
                        }}>
                            Zacznij już teraz

                        </button>
                        <div className="offer-page__offerts__content__block__included">

                            {adventages.map((info, index) => {
                                return (
                                    <div className="offer-page__offerts__content__block__included__el offer-page__offerts__content__block__included__el--true">
                                        <img src={offertsTrue} alt="offertsTrue" />
                                        <h1>{info}</h1>
                                    </div>
                                )
                            })}
                            
                            {disatventages && disatventages.map((info, index) => {
                                return (
                                    <div className="offer-page__offerts__content__block__included__el offer-page__offerts__content__block__included__el--false" key={index}>
                                        <img src={offertsFalse} alt="offertsFalse" />
                                        <h1>{info}</h1>
                                    </div>
                                    )})
                                }
                        </div>
                    </div>
        )
    }

    const renderBack = () => {
        return (
            <div className="offer-block-flipped" style ={{ 
                borderColor: textColor
             }}>

                <div className="texts" style={{
                    color: textColor
                }}>
                    <h1>{title}</h1>
                    <h3>{subtitle}</h3>
                    <ul>
                        <h2>Po zakupie pakietu otrzymujesz: </h2>
                        {adventages.map((info, index) => {
                            return (
                                <li key={index}>{info}</li>
                            )
                        })}
                    </ul> 
                    <h3>{text}</h3>
                    <BuyBlock normalPrice={price} discountPrice={discountPrice} color={textColor} isRecurring={true} isPromoBlock={true} sixMPrice={sixMPrice} threeMPrice={threeMPrice}></BuyBlock>
                  
                    <CloseBtn onClick={handleClick} color={textColor}></CloseBtn>

                </div>
            </div>
        )
    }
    return (
      <>
      {flipped ? renderBack() : renderFront()}
      </>
    )


  
}

export default OfferBlock;