import React, { createContext } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useContext } from "react";

gsap.registerPlugin(ScrollTrigger);

export const Context = createContext(); // Create the context

const ContextProvider = ({ children }) => {
  const appearAnim = (element, onEnter, onLeave) => {

    const settings = {
    
    }

    ScrollTrigger.config({
      ignoreMobileResize: true
    });
    
    ScrollTrigger.batch(element, {

      onEnter: (batch, batchIndex) => {
        gsap.to(element, {
          ...onEnter, ...settings
        }) // Pass arguments to the onEnter function

        console.log('entered' );
      },
      onLeave: (batch, batchIndex) => {
      gsap.to(element, {
        ...onLeave, ...settings
      }) // Pass arguments to the onLeave function
      },
      onEnterBack: (batch, batchIndex) => {
        gsap.to(element, {
          ...onEnter, ...settings
        }) // Pass arguments to the onEnter function
      },
      onLeaveBack: (batch, batchIndex) => {
        gsap.to(element, {
          ...onLeave, ...settings
        }) // Pass arguments to the onLeave function
      },
    });
  };

  return (
    <Context.Provider value={appearAnim}>
      {children}
    </Context.Provider>
  );
};

export const useAnimation = () => useContext(Context);

export default ContextProvider;
