import alanReviews from '../img/reviews.png'
import alanReviewsForm from '../img/sendReview.png'
import arrow from '../blobs/blob-reviews-arrow.svg'
import formBlob from '../blobs/blob-form.svg'
import mobile from '../img/Mobile.svg'
import dashedBorder from '../blobs/border-dashed.svg'
import cloud from '../img/sendReview.png'
import Phone from '../components/Phone';

import  { useAnimation } from '../components/technical/Context';
import "./ReviewsPage.scss";
import { useEffect, useLayoutEffect, useRef } from 'react';
import axios from 'axios';
import FacebookReview from '../components/FacebookReview';

const ReviewsPage = () => {

    const titles1 = useRef(null);
    const line2 = useRef(null);
    const alan3 = useRef(null);
    const alan4 = useRef(null);
    const blob5 = useRef(null);
    const phone6 = useRef(null);
    const animate = useAnimation();

    useLayoutEffect(() => {
        const arr = [
            titles1.current,
            line2.current,
            alan3.current,
            alan4.current,
            blob5.current,
            phone6.current

        ];

        arr.forEach((item, index) => {
            animate(item, {opacity: 1,  duration: .5, stagger: 0.5, delay: index*0.1}, {opacity: 0,  duration: 0.5, stagger: 0.5, delay: index*0.1})
        });

     
    }, []);
    return (
        <section className="reviews-page" id="reviews-page">
            <div className="reviews-page__content padding100gap100">
                <div className="reviews-page__content__left ">
                    <div className="main-titles" ref={titles1}>
                        <h1 className="main-titles__title">
                            Opinie
                        </h1>
                        <h2 className="main-titles__subtitle">
                            co o mnie mówią
                        </h2>
                    </div>
                     <Phone/>
                   
                </div>
                <img className="reviews-page__content__right" src={alanReviews}> 
                    {/* <img src={alanReviews} alt="alan-reviews " className="alanImg" ref={alan3}/> */}
                </img>
            </div>
        </section>
        
    )
}

export default ReviewsPage;