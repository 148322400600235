import leftBlob from '../blobs/blob-training.svg';
import rightBlob from '../blobs/blob-diet.svg';

const offerData = {

    recurring: [
        {
            title: 'Silver',
            subtitle: 'Popraw swoje zdrowie i sylwetkę dzięki pakietowi Silver!',
            price: 299,
            discountPrice: 249,
            threeMPrice: 499,
            sixMPrice: 869,
            textColor: '#7D7D7D',
            adventages: [
              "Ocena stanu fizycznego",
              "Indywidualny plan dietetyczny (makroskładniki, kalorie, suplementacja)",
              "Indywidualny plan treningowy",
              "Motywacja i wsparcie",
              "Monitorowanie i analiza postępów",
              "Wideo analiza",
              "Raporty efektów co 14 dni",
              "Rabat -10% na treningi personalne",
            ],
            disatventages: [
                "Rozmowa telefoniczna",
                "Możliwość konsultacji video",
                "Wideo analiza techniki wykonywanych ćwiczeń",
                "Dostęp do ebooka",
                "Koszulka z logo"
            ],
            text: "Rozpocznij inwestycję w siebie i osiągnij niesamowite wyniki dzięki mojemu pakietowi współpracy online - Silver! Zdobądź zdrowe nawyki i utrzymaj formę na lata. Zaufaj mi, i zobacz, jak Twoje życie nabierze nowego impetu. Czas zainwestować w siebie!",
        },
        {
            title: 'Gold',
            subtitle: 'Osiągnij wyższy poziom treningu i zdrowia dzięki ofercie Gold!',
            price: 349,
            threeMPrice: 599,
            sixMPrice: 1049,
            discountPrice: 299,
            textColor: '#f6ce4b',
            adventages: [
              "Ocena stanu fizycznego",
              "Indywidualny plan dietetyczny (makroskładniki, kalorie, suplementacja)",
              "Indywidualny plan treningowy",
              "Motywacja i wsparcie",
              "Monitorowanie i analiza postępów",
              "Wideo analiza",
              "Raporty efektów co 7 dni",
              "Rozmowa telefoniczna",
              "Rabat -15% na treningi personalne",

            ],
            disatventages: [
              "Możliwość konsultacji video",
              "Wideo analiza techniki wykonywanych ćwiczeń",
              "Dostęp do ebooka",
              "Koszulka z logo"
            ],
            text: "Osiągnij wyższy poziom treningu i żyj zdrowiej dzięki ofercie Gold! Pomogę ci dostosować plan treningowy i dietetyczny do Twoich unikalnych potrzeb. Zyskaj wsparcie, motywację i spersonalizowane analizy postępów. Nie czekaj dłużej!",
        },
        {
            title: 'Diamond',
            subtitle: 'Postaw na najwyższą jakość i pełne zaangażowanie z ofertą Diamond!',
            price: 429,
            threeMPrice: 699,
            sixMPrice: 1199,
            discountPrice: 349,
            textColor: '#48c9f0',
            adventages: [
              "Ocena stanu fizycznego",
              "Indywidualny plan dietetyczny (makroskładniki, kalorie, suplementacja)",
              "Indywidualny plan treningowy",
              "Motywacja i wsparcie",
              "Monitorowanie i analiza postępów",
              "Wideo analiza",
              "Raporty efektów co 7 dni",
              "Rozmowa telefoniczna",
              "Możliwość konsultacji video",
              "Wideo analiza techniki wykonywanych ćwiczeń",
              "Dostęp do ebooka",
              "Koszulka z logo",
              "Rabat -20% na treningi personalne",
            ],
            text: "Postaw na najwyższą jakość i pełne zaangażowanie",
          },

    ],
    oneTime: [
        {
            bgImg: leftBlob,
            title: 'Trening',
            text: 'Plan treningowy jest dokładnie spersonalizowany pod twój cel sylwetkowy i uwzględniam w nim każdą partię mięśniową, na której chciał/a byś się skupić najbardziej. Wszystko jest dostosowane na podstawie twojego trybu życia,stażu treningowego,celu oraz twoich możliwości. Rozpisuje go na podstawie analizy składu ciała oraz wywiadu treningowego.',
            normalPrice: 119,
            discountPrice: 89,
            adventages: [
            "Indywidualny plan treningowy",
            "Możliwość kontroli techniki na podstawie przesłanych filmików",
            "Stały kontakt poprzez Instagram lub e-mail",
            "Raporty efektów co 7 dni (opcjonalnie)"
            ],
        },
        {
            bgImg: rightBlob,
            title: 'Dieta',
            text: 'Dieta jest indywidualnie dopasowywana do potrzeb klienta, aby nie musiał rezygnować ze swojego ulubionego jedzenia. Wszystkie dania są zbilansowane pod względem makroskładników i mikroskładników oraz są szybkie i łatwe w przygotowaniu, a produkty są na tzw. ,,studencką kieszeń”.Dieta jest przygotowywana na podstawie analizy składu ciała oraz wywiadu żywieniowego.',
            normalPrice: 199,
            discountPrice: 149,
            adventages: [
            "Indywidualny plan dietetyczny (odpowiedni dobór makroskładników i kalorii)",
            "Sposób przygotowania posiłków",
            "Rozpisana podstawowa suplementacja",
            "Stały kontakt poprzez Instagram lub e-mail",
            "Raporty efektów co 7 dni (opcjonalnie)"
            ],
        },
        {
            bgImg: null,
            title: 'Koszulka Trenera Alana Słomińskiego',
            text: 'Styl i Motywacja w Jednym',
            normalPrice: 89.99,
            discountPrice: 79.99,
            options: [
              {
                name: 'Rozmiar',
                values: ['XS','S', 'M', 'L', 'XL', 'XXL']
              },
              {
                name: 'Kolor',
                values: ['Czarny', 'Biały']
              },
              {
                name: 'Materiał',
                values: ['Bawełna', 'Poliester']
              }
            ]


        }
    ],


    
}

export default offerData;