import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import './HamMenu.scss';


const HamMenuWrapper = ({className, onClick}) => {


  const [activeNavLink, setActiveNavLink] = useState(null);
  const observerRef = useRef(null);

  const scrollToSection = (sectionId) => {
        const section = document.querySelector('#' + sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
        setTimeout(() => {
          onClick();
        }, 500)
      };
  

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const sectionId = entry.target.id;
        if (entry.isIntersecting) {
          setActiveNavLink(sectionId);
        }
      });
    };


    const observer = (options) => {
      return new IntersectionObserver(handleIntersection, {...options})
    }

    

    const observerOptions = {
      threshold: 0.25,
    };

    observerRef.current = new IntersectionObserver(handleIntersection, observerOptions);

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observerRef.current.observe(section));
    sections.forEach((section) => {

      if(section.id === 'offer-page') {
        const threshold = 0.1;
        observer({
          root: null,
          rootMargin: '0px',
          threshold: threshold
        }).observe(section);
      }
      if(section.id==='glowups-page') {
        const threshold = 0.1;
        observer({
          root: null,
          rootMargin: '0px',
          threshold: threshold
        }).observe(section);
      }
      else {
        observer({
          root: null,
          rootMargin: '0px',
          threshold: 0.3
        }).observe(section);
      }
    })

    return () => {
      observerRef.current.disconnect();
    };
  }, []);


      const MenuBorder =() => {
        return (
            <svg  className ="menu-border"width="105" height="1674" viewBox="0 0 105 1674" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M96 0L51.7374 0C30.5097 66.2688 20.9764 143.645 24.9637 219.093C30.0985 316.659 56.8148 408.138 60.8213 505.856C64.9904 608.247 44.6806 710.445 27.1056 808.427C9.89399 904.481 3.31533 1000.2 14.5985 1098.79C20.3357 1148.92 31.6572 1195.65 42.6535 1243.63C72.0949 1372.09 109.664 1543.36 45.8281 1664H96L96 0Z" fill="#C464FF"/>
                <path opacity="0.3" d="M96 0L51.7374 0C30.5097 66.2688 20.9764 143.645 24.9637 219.093C30.0985 316.659 56.8148 408.138 60.8213 505.856C64.9904 608.247 44.6806 710.445 27.1056 808.427C9.89399 904.481 3.31533 1000.2 14.5985 1098.79C20.3357 1148.92 31.6572 1195.65 42.6535 1243.63C72.0949 1372.09 109.664 1543.36 45.8281 1664H96L96 0Z" fill="#C464FF"/>
                <path opacity="0.5" d="M105 10L89.9072 10C69.7547 28.0267 50.7192 48.3275 36.2183 76.1301C-1.22269 147.849 -0.964935 238.8 17.5742 321.418C27.2637 364.612 42.4616 404.742 55.5688 445.76C73.7069 502.502 99.4822 563.252 102.986 627.178C105.706 677.458 93.993 725.492 72.8573 763.903C48.619 807.957 13.6697 850.32 3.16865 907.603C-7.13191 963.68 9.55519 1020.41 26.3473 1072.8C43.1394 1125.19 63.5782 1177.02 67.4445 1234.93C73.0291 1317.75 45.6024 1392.01 30.3663 1469.13C22.0991 1511.01 24.4762 1550.95 37.5261 1589.9C47.9221 1621 63.2345 1656.46 84.5325 1674H105L105 10Z" fill="#9100C4"/>
                <path opacity="0.5" d="M105 10L89.9072 10C69.7547 28.0267 50.7192 48.3275 36.2183 76.1301C-1.22269 147.849 -0.964935 238.8 17.5742 321.418C27.2637 364.612 42.4616 404.742 55.5688 445.76C73.7069 502.502 99.4822 563.252 102.986 627.178C105.706 677.458 93.993 725.492 72.8573 763.903C48.619 807.957 13.6697 850.32 3.16865 907.603C-7.13191 963.68 9.55519 1020.41 26.3473 1072.8C43.1394 1125.19 63.5782 1177.02 67.4445 1234.93C73.0291 1317.75 45.6024 1392.01 30.3663 1469.13C22.0991 1511.01 24.4762 1550.95 37.5261 1589.9C47.9221 1621 63.2345 1656.46 84.5325 1674H105L105 10Z" fill="#9100C4"/>
                <path d="M105 0L99.6121 0C48.5369 209.152 36.7467 438.156 64.2605 663.783C71.5719 723.768 83.5153 781.284 89.5827 841.799C97.8416 924.104 77.869 998.708 55.7049 1072.76C31.1004 1154.96 13.8552 1235.97 18.8699 1326.92C25.5689 1447.63 62.6144 1567.51 100.033 1674H105L105 0Z" fill="white"/>
                </svg>
        )
      }
        return ( 
          
      
            <div className={className}>  


            <MenuBorder/>
           
               
                <div className="ham-menu-wrapper__content">
                <nav>
              <Link
                className={`navLink${activeNavLink === 'home-page' ? ' active' : ''}`}
                to="#home-page"
                onClick={() => scrollToSection('home-page')}
              >
                Start
              </Link>
              <Link
                className={`navLink${activeNavLink === 'about-me' ? ' active' : ''}`}
                to="#about-me"
                onClick={() => scrollToSection('about-me')}
              >
                O mnie
              </Link>
              <Link
                className={`navLink${activeNavLink === 'reviews-page' ? ' active' : ''}`}
                to="#reviews-page"
                onClick={() => scrollToSection('reviews-page')}
              >
                Opinie
              </Link>
              <Link
                className={`navLink${activeNavLink === 'glowups-page' ? ' active' : ''}`}
                to="#glowups-page"
                onClick={() => scrollToSection('glowups-page')}
              >
                Podopieczni
              </Link>
              <Link
                className={`navLink${activeNavLink === 'offer-page' ? ' active' : ''}`}
                to="#offer-page"
                onClick={() => scrollToSection('offer-page')}
              >
                Oferta
              </Link>
           
            </nav>        
                </div>
            </div>
        )
}

export default HamMenuWrapper;


    




    




