import './PromoBlock.scss';
import './BuyBlock.scss';
import { useEffect } from 'react';

 const PromoBlock = ({ price, color, threeMPrice, sixMPrice })=> {


    const price3m = price * 2;
    const price6m = price * 3.5;


    const promoValue3m = ((price * 3) - price3m).toFixed(0);
    const promoValue6m = price * 6 - price6m.toFixed(0);


    const monthPrice6m = (price6m / 6).toFixed(0);
    const monthPrice3m = (price3m / 3).toFixed(0);

    const percentage3m = ((price - monthPrice3m) / price * 100).toFixed(0);
    const percentage6m = ((price - monthPrice6m) / price * 100).toFixed(0);

    const Block = ({percentage, promoValue, wholePrice, duration, monthPrice}) => {
        return (
            <div className="row">
                <div className="pricing">
                    <div className="pricing__discount-price"> 
                    <span>PLN</span>{monthPrice}<span className="pricing__recurring">/mies</span> </div>
                </div>

                <div className="info-block" style={{ backgroundColor: color}}>
                    <p>Oszczędzasz { promoValue }<span className="currency" >PLN</span> </p>
                    <div className='percentage'>{percentage * -1}%</div>
                    <p> <span className="currency">PLN</span>
                       {wholePrice}<span className="duration">/{duration}mies</span>
                    </p>
                </div>
           </div>
        )
    }


    return (
        <div className="promo-block">
         
                <Block 
                percentage={percentage3m} 
                promoValue={promoValue3m} 
                wholePrice={threeMPrice} 
                duration={3}
                color={color}
                monthPrice={monthPrice3m}
                > 
                </Block>
                <Block 
                percentage={percentage6m} 
                promoValue={promoValue6m} 
                wholePrice={sixMPrice} 
                duration={6}
                monthPrice={monthPrice6m}
                color={color}
                > 
                </Block>
           
        </div>
    )
}

export default PromoBlock;