import React, { useEffect } from "react";
import ContextProvider from "./components/technical/Context";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import YourSteps from "./components/YourSteps";
import AboutMePage from "./pages/AboutMePage";
import ReviewsPage from "./pages/ReviewsPage";
import HelpPage from "./pages/HelpPage";
import OfferPage from "./pages/OfferPage";
import Footer from "./components/Footer";
import GlowUpsPage from "./pages/GlowUpsPage";
import NewsletterPage from "./pages/NewsletterPage";
import "./styles/style.scss";
import "./styles/media.scss";
// import { auth } from './firebase';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
// import store from './redux/store';
// import { Provider, useDispatch } from 'react-redux';
// import { login, logout } from './redux/userSlice';
import Account from './components/auth/Account';

import TestPage from "./pages/TestPage";

const App = () => {

  // const user = null;
  // const dispatch = useDispatch();

 

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((authUser) => {

  //     if(authUser) {
  //       dispatch(login({
  //         email: authUser.email,
  //         uid: authUser.uid,
  //         displayName: authUser.displayName,
  //       }));
  //     } else {
     
  //       dispatch(logout);
  //     }
  //   });
  //   return unsubscribe;
   
  // });

  return (


      <ContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="" element={
           
              <div className="App">
                <Header />
                <HomePage />
                <YourSteps /> 
                <AboutMePage /> 
                 <ReviewsPage />
                <GlowUpsPage /> 
               <HelpPage />
                <OfferPage />   
                 {/* <NewsletterPage />    */}

                 {/* <TestPage></TestPage> */}
                <Footer /> 
              </div> 

          
            
         } />
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/account" element={<Account />} />
        </Routes>
    </BrowserRouter>
     </ContextProvider>
  
  

    
 
   
  );
};

export default App;
