import React, { useState,  useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import './OfferBlob.scss';
import BuyBlock from './BuyBlock';
import CloseBtn from './CloseBtn';
import { gsap } from 'gsap';
import dietImg from '../img/offerPage/diet_preview.png';
import trainingImg from '../img/offerPage/training_preview.png';
import Overlay from './Overlay';


const OfferBlob = ({ bgImg, title, text, normalPrice, discountPrice, adventages }) => {
  const [flipped, setFlipped] = useState(false);
  const isTablet = useMediaQuery({ maxWidth: 900 });
  const className = title === "Trening" ? "training" : "diet";
  const ref = useRef(null);
  const overlayRef = useRef(null);
  const imgRef = useRef(null);


  const handleClose= () => {
    const tl = gsap.timeline();
    tl.to(overlayRef.current, { opacity: 0, duration: 0.1, display:"none"}).to(imgRef.current, {  opacity: 0, duration: 0.3, display:"none"}, '-=0.3')
  }
  const handleButtonClick = () => {
    const tl = gsap.timeline();

    tl.to(overlayRef.current, { opacity: 1, duration: 0.2, display:"block"}).to(imgRef.current, { opacity: 1, duration: 0.3, display:"block"}, '-=0.3');
  };
  


  const handleClick = () => {

    const tl = gsap.timeline();
    tl.set(ref.current, {
      overwrite: true
    })
    if (!flipped) {
      tl.to(ref.current, { opacity:0, duration: 0.25,   onComplete: () => {
        setFlipped(true);
      }}).to(ref.current, { opacity:1, duration: 0.25 });
    }
    else {
      tl.to(ref.current, { opacity:0, duration: 0.25 , onComplete: () => {
        setFlipped(false);
      }}).to(ref.current, { opacity:1, duration: 0.25 });
    }
  
  };

  const renderFrontContent = () => {
    return (
      <>
        <h2 className={`offer-page__content__${className}__title`} >{title}</h2>
        <p className={`offer-page__content__${className}__text`}>{text}</p>
        <button className={`offer-page__content__${className}__btn`} onClick={handleClick}>
          Zacznij już teraz
        </button>
      </>
    );
  };

  const renderBackContent = () => {
    return (
      <>
        <div className={`offer-page__content__${className}__title flipped`} >
            <h1>{title}</h1>
            <h2>W pakiecie {title} otrzymujesz:</h2>
            <ul>
              {adventages.map((adv, index)=> {
                return <li key={index} >{adv}</li>
              })}
            </ul> 

            {isTablet &&  <img src={className === "diet" ? dietImg : trainingImg} className="plan-photo" alt="plan treningowy / dieta" onClick={handleButtonClick}/>}

            <BuyBlock normalPrice={normalPrice} discountPrice={discountPrice} color={"#C464FF"} isPromoBlock={false} isRecurring={true}/>
        </div>
        {!isTablet &&  <img src={className === "diet" ? dietImg : trainingImg} className="plan-photo" alt="plan treningowy / dieta" onClick={handleButtonClick}/>}
        <CloseBtn onClick={handleClick} ></CloseBtn>

        <Overlay onCloseClick={handleClose} ref={overlayRef}/>
        <img src={className === "diet" ? dietImg : trainingImg} className="plan-photo-zoomed" alt="plan treningowy / dieta" onClick={handleClose} ref={imgRef}/>
      </>
    );
  };

  return (
    <>
      
      <div className={`offer-page__content__${className} ${flipped ? 'flipped' : ''}`} style={{ backgroundImage: `url(${bgImg})` }} ref={ref}>
        {flipped ? renderBackContent() : renderFrontContent()}
      </div>
    </>
  );
};

export default OfferBlob;
