import bartek59 from '../img/glowups/Bartek 59.jpg';
import bartek69 from '../img/glowups/Bartek 69.jpg';
import damian94 from '../img/glowups/Damian 94.jpg';
import damian120 from '../img/glowups/Damian 120.jpg';
import filip57 from '../img/glowups/Filip 57.jpg';
import filip72 from '../img/glowups/Filip 72.jpg';
import gosia73 from '../img/glowups/Gosia 73.jpg';
import gosia85 from '../img/glowups/Gosia 85.jpg';
import krzysztof68 from '../img/glowups/Krzysztof 68.jpg';
import krzysztof80 from '../img/glowups/Krzysztof 80.jpeg';
import kuba80 from '../img/glowups/Kuba 80.jpg';
import kuba100 from '../img/glowups/Kuba 100.jpg';
import marcin110 from '../img/glowups/Marcin 110.jpg';
import marcin140 from '../img/glowups/marcin 140.jpg';


const glowupsData = [
    {
        name: 'Marcin',
        desc: 'Alan zainspirował mnie do pokonania przeszkód i osiągnięcia niesamowitych rezultatów.',
        wghChange: -30,
        time: 6,
        imgUrl1: marcin110,
        imgUrl2: marcin140,
    },
    {
        name: 'Damian',
        desc: 'Alan to nie tylko trener, ale również mentor, który pomógł mi w odnalezieniu równowagi w życiu.',
        wghChange: -26,
        time: 4,
        imgUrl1: damian94,
        imgUrl2: damian120,
    },
    {
        name: 'Filip',
        desc: 'Moje życie zmieniło się na lepsze, nabrałem pewności siebie i zacząłem cieszyć się życiem.',
        wghChange: 12,
        time: 9,
        imgUrl1: filip57,
        imgUrl2: filip72,
    },
    {
        name: 'Gosia',
        desc: 'Zgubiona tkanka tłuszczowa to zdrowsze życie i lepsza jakość życia.',
        wghChange: -12,
        time: 3,
        imgUrl1: gosia73,
        imgUrl2: gosia85,
    },
    {
        name: 'Krzysztof',
        desc: 'Zbiłem znacząco poziom tkanki tłuszczowej, bardziej widać mi żyły.',
        wghChange: -12,
        time: 3,
        imgUrl1: krzysztof68,
        imgUrl2: krzysztof80,
    },
    {
        name: 'Kuba',
        desc: 'Alan pomógł mi w odnalezieniu równowagi w życiu, dzięki czemu mogę cieszyć się życiem.',
        wghChange: -20,
        time: 6,
        imgUrl1: kuba80,
        imgUrl2: kuba100,
    },
    {
        name: 'Bartek',
        desc: 'Koleżanki z klasy zaczęły się mną interesować, a ja zacząłem cieszyć się życiem.',
        wghChange: 10,
        time: 12,
        imgUrl1: bartek59,
        imgUrl2: bartek69,
    }
];


export default glowupsData;