import arrowIcon from '../icons/arrow.svg';
import './ScrollDown.scss';

const ScrollDown = () => {

    const scrollToYourSteps = (e) => {
        e.preventDefault();
        document.querySelector('.your-steps-wrapper').scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <div className="scroll-down"  onClick={scrollToYourSteps}>
           <img src={arrowIcon} alt="" />
        </div>
            
    )


}


export default ScrollDown;