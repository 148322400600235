import React, { useState, useEffect, useRef } from "react";
import WaveBorder from "./WaveBorder";
import arrowIcon from "../icons/arrow.svg";
import crownIcon from "../icons/crown.svg";
import blob1 from "../blobs/blob-step1.svg";
import blob2 from "../blobs/blob-step2.svg";
import blob3 from "../blobs/blob-step3.svg";
import blobWrapped from "../blobs/blob-steps-wrapped.svg";
import  { useAnimation } from '../components/technical/Context';

import "./YourSteps.scss";

const YourSteps = () => {
    const first = useRef(null);
    const second = useRef(null);
    const third = useRef(null);
    const arr = [first.current, second.current, third.current];
    const animation = useAnimation();
    useEffect(() => {

        const onEnter = {
            opacity:1,
            right: 0,
            transform: 'none'
        }
        const onLeave = {
            opacity: 0,
            right: 75,
            transform: "scale(0.8)"
        }

        arr.forEach((el, index)=> {
            index+=1;
            animation(
                el, 
                {...onEnter, delay: `${index*0.225}`, duration: .2}, 
                {...onLeave, delay: `${index*0.225}`, duration: .2}, );
        })
    });



    const [isWrapped, setIsWrapped] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
    const [beforeParentClassName, setBeforeParentClassName] = useState(""
    );

    useEffect(() => {
        setCurrentWidth(window.innerWidth);

        currentWidth < 1574 ? setIsWrapped(true) : setIsWrapped(false);
        setBeforeParentClassName(isWrapped ? "your-steps-wrapper__inner__element wrapped" : "your-steps-wrapper__inner__element")


        window.addEventListener("resize", () => {
            setCurrentWidth(window.innerWidth);
        });
    }, [currentWidth, isWrapped]);

    return (
       <div id="your-steps-wrapper" className="your-steps-wrapper">

    
        <WaveBorder rotated={true} />

        <div className="your-steps-wrapper__inner" style={{
            flexWrap: isWrapped ? "wrap" : "nowrap"
        }}>
            <div className={`your-steps-wrapper__inner__element--1 ${beforeParentClassName}`}  style={
                {backgroundImage: isWrapped ? `url(${blobWrapped})` : `url(${blob1})`}
            } ref={first}>
                <div className="your-steps-wrapper__inner__element__text"><h1>OBIERZ SWÓJ CEL.</h1><h2>Zdecyduj co chcesz w sobie zmienić. <br></br>Najlepszy czas na zmianę jest teraz! </h2></div>
                {!isWrapped &&<img className="your-steps-wrapper__inner__element__icon" src={arrowIcon} alt="arrowIcon"/>}
            </div>
            <div className={`your-steps-wrapper__inner__element--2 ${beforeParentClassName}`}  style={
                {backgroundImage: isWrapped ? `url(${blobWrapped})` : `url(${blob2})`,
                }
            }ref={second} >
                <div className="your-steps-wrapper__inner__element__text"><h1>WYBIERZ PLAN.</h1><h2>Wybierz plan, który najbardziej <br></br>dopasuje się do twoich potrzeb</h2></div>
                {!isWrapped &&<img className="your-steps-wrapper__inner__element__icon" src={arrowIcon} alt="arrowIcon"/>}
            </div>
            <div className={`your-steps-wrapper__inner__element--3 ${beforeParentClassName}`}  style={
                {backgroundImage: isWrapped ? `url(${blobWrapped})` : `url(${blob3})`}
            } ref={third}>
                <div className="your-steps-wrapper__inner__element__text"><h1>ZOBACZ EFEKTY.</h1><h2>Poczuj się lepiej w swojej nowej  <br />    odbudowanej sylwetce</h2></div>
                {!isWrapped &&<img className="your-steps-wrapper__inner__element__icon" src={crownIcon} alt="crownIcon"/>}
            </div>
        </div>
        <WaveBorder rotated={false} />
        </div>
    )
}

export default YourSteps;