const reviewsData = [
    {
        id: 1,
        

    },
    {
        id: 2,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fgabrysia.radaszkiewicz%2Fposts%2Fpfbid0S4dyULNXvNMGeo2o47BEzvhi7REdXCGpurfzzdu295razkvGAYcG1z2zb88T8GTtl&show_text=true&width=500" width="100%" height="180" style="border:none;overflow:visible display:flex" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy" data-show-text="true"></iframe>`

    },
    {
        id: 3,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnikola.czubek%2Fposts%2Fpfbid0aXkowjR2oHdSSuzJC3hKEWZFG7NaD7KjmnAD329FWsLuHhBdSmZDXjnYM7WHu1Mxl&show_text=true&width=500" width="100%" height="255" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 4,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmateusz.grudzien.39108%2Fposts%2Fpfbid0G1nSsvzT3sJabXLYam2gkTi4WpwK8GC5mBMXJuxcGoYooeiq8ix7QXEcJrXoWxrGl&show_text=true&width=500" width="100%" height="240" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 5,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmonika.kudela.9%2Fposts%2Fpfbid0ZEctaJA65iEcaL6ddo2hscB1xWjyvVuew7b4KQ7623bw7MUejc1wnHuL2U8Xdfcfl&show_text=true&width=500" width="100%" height="200" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 6,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0BCXw3i8jDHotu3N76vcLvVr179XtRwS7Q2CTuhKhP8GPxcuwhA3tbuv6KapGxDVzl%26id%3D100010180952688&show_text=true&width=500" width="100%" height="220" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 7,
        post: ``
    },
    {
        id: 8,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0i8dvKuHCdDH4exvdgJx6W1CrXykfpsMUMPPPbnB8aDPnyrFbzVZ95H96KtxWbYTgl%26id%3D100001752390860&show_text=true&width=500" width="100%" height="180" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 9,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Folek.pw%2Fposts%2Fpfbid0H2KYdKtYpVMCoe8Q3J8T7khm6dizDMVfECxSHj1MgEDrbR2AZcNgycoipdzwkevzl&show_text=true&width=500" width="100%" height="200" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 10,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbilychyura%2Fposts%2Fpfbid0iaJTHXeEo2dDG9UXXKCHKuTmReu9JVygB3dVpxdjDBYX5oAUZn1gUEDp6CZVXJDwl&show_text=true&width=500" width="100%" height="257" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 11,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Faniakumorek%2Fposts%2Fpfbid02PdeUoU7KxnboHM7cqYXmdoQ9wrqEZRZe32M8yn8CfAqUHuesp52p2kiyEqokZbswl&show_text=true&width=500" width="100%" height="355" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 12,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbartosz.rychel.3%2Fposts%2Fpfbid02RxCCR9wfiNY8FXXdTDduo2NZVFNAdHML8Eg4DmWoZmjC25vahHtMRGrG4jgrAC3El&show_text=true&width=500" width="100%" height="233" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 13,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmagdalena.schaefer.5%2Fposts%2Fpfbid0vdnv9ciizx9LdTNAKHZdU2HrSRSd6MZpFMGmdg5PgRjmMZ83t1SA85hAyMGLkLk7l&show_text=true&width=500" width="100%" height="232" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 14,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmagdalena.florczyk.56%2Fposts%2Fpfbid0e8toT5NYTvqF81E6e6bh1yTNxA7hEHrQ3EA3p7BtF12ZnzYZAcbuViViVGb7zE8gl&show_text=true&width=500" width="100%" height="232" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 15,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fwxgnerek%2Fposts%2Fpfbid0H9Cbk6SMJmXbDqpdw4GLipgrYR4tSLzDJnKfBnngvH9D5AEUH3ruxsfynhqr9B93l&show_text=true&width=500" width="100%" height="232" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 16,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ftatiana.sysak.5%2Fposts%2Fpfbid0iDsxhdWPBtQG2FWzyYoM4eSdWsq2vCV3X89ATfmXWSCXuSGx44TYUmCPJY59QDDrl&show_text=true&width=500" width="100%" height="392" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 17,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffoorys%2Fposts%2Fpfbid02hhJL3fHKpCVHiijZgQxUqyw4zYjwy9giShGf1pGJBeuw7RXsJGjpdFp4CQobLFeul&show_text=true&width=500" width="100%" height="235" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 18,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkamil.marzec.902%2Fposts%2Fpfbid038P3WcdrgjsXUH1kRRzQT3sJ9KwsdqiNhxBhboEySRXxjv738PPczo7obwM6Z8vRel&show_text=true&width=500" width="100%" height="232" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    }, {
        id: 19,
        post:`<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Foskibb%2Fposts%2Fpfbid02fWwg9B45h3gq8mrmVxfYjGRUSaHUFj7NMAhnX6KzLjTrxM2vXBZKvXneKGoHM37el&show_text=true&width=500" width="100%" height="198" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 20,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkonrad.iwan.52%2Fposts%2Fpfbid02rhm5PDw98g3p6xeVa1g74ULMHnbkTbZTXDyfZZMi8fVBXbKYLVBbBjAkiCoKiF6ol&show_text=true&width=500" width="100%" height="217" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 21,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkornel.jaworski.10%2Fposts%2Fpfbid02jtdzPyJYHr1E9Vnz6k1WKVeLTbvZrUXpLcAw88fMVmWpPNWARbVEPvfKVd1fd7Mol&show_text=true&width=500" width="100%" height="183" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 22,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0YGhnaXjUkpj4nJHNCzrvab5rn8sTXCjiiMVT7kGTk8bpCkoWyGk8QiYNptwjN8Cvl%26id%3D100009319836508&show_text=true&width=500" width="100%" height="240" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 23,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Faneta.oziminska%2Fposts%2Fpfbid02EikmzEx1zUzLEfvig2mD1KCXxTgb1WWNNir6hfvfh5TwLsf4NHDGNbathfRUyYRMl&show_text=true&width=500" width="100%" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 24,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkuba.stach.3%2Fposts%2Fpfbid0g4PyrmKLVSMey7u6BYxyma2tt9z7f7K4ZhAbzYRyLKmiZ8MwSeNooznN6Yi4C4fml&show_text=true&width=500" width="100%" height="190" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 25,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fanna.gryglewicz.5%2Fposts%2Fpfbid02n3aJKpiiM7MNjL26xhQQC3c2ujD5YjZRi9L7iuTmGYrHVzPaHtKvxj5HZTSRSxzVl&show_text=true&width=500" width="100%" height="200" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 26,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0iYzU8LE3Vb6a6cyu5UrK3jNbdQJ22XRkZaujockPvTi9cHS5168tyrUezPqoLXQfl%26id%3D100005066142138&show_text=true&width=500" width="100%" height="188" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 27,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsebastian.dymucha.75%2Fposts%2Fpfbid0Kr8pqKzVNfKE7BAB6Qccy6T5vK2xXcXhiGHXAL5NQj6sKTWTvz362HDrMk4SLJBWl&show_text=true&width=500" width="100%" height="193" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 28,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fada.antonik.a%2Fposts%2Fpfbid0Xqau6gWDPcuAHeFbhDoyaY6MLnFMmeFASmoEbC6WT2yzfaXkJJ7QUeJP6GSSzs2Jl&show_text=true&width=500" width="100%" height="245" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 29,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02RPKcC9mWGyUdkWCyD4sbeM4pQu61ZJAJtkTaVqqWc9eswukRbaHYxZtqYaugzUKFl%26id%3D100009872372975&show_text=true&width=500" width="100%" height="230" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allo wfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 30,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fm.cierniowska%2Fposts%2Fpfbid0iWNSwU7TD9uKBLyQyUFbSt1XPXDuWRErUouRo7yJSAvqfx9de7SNMZyYmgPpfJxEl&show_text=true&width=500" width="100%" height="215" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 31,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmnjohnny92%2Fposts%2Fpfbid02K9Q69GwHpabsSMxiEi1tPw97FPwxDtmzT2Cc3J5Mupg84jBf2f3MWoatZ4EVqNJ1l&show_text=true&width=500" width="100%" height="352" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    {
        id: 32,
        post: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkinga.kinia.589%2Fposts%2Fpfbid02aavR99mETBAtmFHv6GSHuvcgKHToLznGLErPHHfhEP9uJHmB6TJcmftp8cM1e4Qrl&show_text=true&width=500" width="100%" height="169" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" data-lazy="true" loading="lazy"></iframe>`
    },
    

];

const reviewsDataByDate = (data) => {
    const segregatedReviews = [];

    reviewsData.forEach((review) => {
        const dateMatch = review.post.match(/<a href=".*?">(.+?)<\/a>/);
        const dateString = dateMatch ? dateMatch[1] : '';
        const date = new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

        if (!segregatedReviews[date]) {
            segregatedReviews[date] = [];
        }
        segregatedReviews[date].push(review);
    });

    return segregatedReviews;
};


export { reviewsData, reviewsDataByDate };