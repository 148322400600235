import React, { useEffect, useRef, useState } from 'react';
import alanImg from '../img/aboutMe.png';
import alanImg2 from '../img/aboutMe3.png';
import blob1 from '../blobs/blob-aboutMe-content-1.1.svg';
import blob2 from '../blobs/blob-aboutMe-content-2.svg';
import mediaBlob1 from '../blobs/blob-media-aboutMe-1.svg';
import mediaBlob2 from '../blobs/blob-media-aboutMe-2.svg';
import line1 from '../blobs/line-aboutMe-1.svg';
import { useAnimation } from '../components/technical/Context';
import './AboutMePage.scss';
import { useMediaQuery } from 'react-responsive';

const AboutMePage = () => {
  const alan1 = useRef(null);
  const line2 = useRef(null);
  const title3 = useRef(null);
  const text4 = useRef(null);
  const text5 = useRef(null);
  const animate = useAnimation();

  const arr = [alan1.current, line2.current, title3.current, text4.current, text5.current];

  const isUnder1000 = useMediaQuery({ maxWidth: 1000 });

  useEffect(() => {
    arr.forEach((el, index) => {
      index += 1;
    
      animate(
        el,
        {
          opacity: 1,
          delay: `{${index * 0.25}`,
          duration: 0.5,
        },
        {
          opacity: 0,
          delay: `{${index * 0.25}`,
          duration: 0.5,
        }
      );
    });
  });

  const [cloud1Src, setCloud1Src] = useState(blob1);
  const [cloud2Src, setCloud2Src] = useState(blob2);
  const [alanSrc, setAlanSrc] = useState(alanImg);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        // setCloud1Src(mediaBlob1);
        // setCloud2Src(mediaBlob2);
        setAlanSrc(alanImg2);
      } else {
        setCloud1Src(blob1);
        setCloud2Src(blob2);
        setAlanSrc(alanImg);
      }
    };

    handleResize(); // Call the handler initially

    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      // Cleanup: remove the event listener when the component unmounts
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="about-me padding100gap100" id="about-me">
      <img className="about-me__image" src={isUnder1000 ? '' : alanSrc}>
      
        {/* {!isUnder1000 &&    <img className="alanImg" src={alanSrc} alt="" ref={alan1} />}
        <img className="line dottedArrow" src={line1} alt="" ref={line2} /> */}
      </img>

        <div className="about-me__text" >
        {isUnder1000 && <img className="alanImg" src={alanSrc} alt="" ref={alan1}  style={{
          position: 'absolute',
          top:0,
          margin:0,
          padding:0,
          width: '100%',
        }}/>
        }

        <div className="main-titles" ref={title3}>
          <h1 className="main-titles__title">O mnie</h1>
          <h2 className="main-titles__subtitle">Teraz opowiem trochę o sobie</h2>
        </div>
        <img className="about-me__text__content about-me__cloud-1" src={cloud1Src} alt="blob1" ref={text4} />
        <img className="about-me__text__content about-me__cloud-2" src={cloud2Src} alt="blob2" ref={text5} />
      </div>
    </section>
  );
};

export default AboutMePage;
