import './BuyBlock.scss';
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import ContactMe from './ContactMe';
import gsap from 'gsap';
import Overlay from './Overlay';
import PromoBlock from './PromoBlock';

const BuyBlock = ({ normalPrice, discountPrice, color, isRecurring, isPromoBlock, threeMPrice, sixMPrice }) => {
  const [showContactMe, setShowContactMe] = useState(false);
  const contactMeRef = useRef(null);
  const overlayRef = useRef(null);

  const handleButtonClick = () => {
    if (document.querySelector('.contact-me')) {
      return;
    }
    setShowContactMe(!showContactMe);
  };

  const handleCloseClick = () => {
    const tl = gsap.timeline();
    tl.to(overlayRef.current, { opacity: 0, duration: 0.1}).to(contactMeRef.current, { y: 0, opacity: 0, duration: 0.3 }).then(() => {
      setShowContactMe(false);
    });
  };

  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.set(contactMeRef.current, {
      overwrite: true
    })
    if (showContactMe) {
      tl.fromTo(contactMeRef.current, { y: 0, opacity: 0 }, { y: '50%', opacity: 1, duration: 0.3 }).to(overlayRef.current, { opacity: 0.5, duration: 0.1, display:"block" }, '-=0.3');
    }
    else {
      tl.to(contactMeRef.current, { y: 0, opacity: 0, duration: 0.3 }).to(overlayRef.current, { opacity: 0, duration: 0.1 }, '-=0.3');
    }
  }, [showContactMe]);

  return (
    <div className="buy-block">
      <div className="pricing">
        <p
          className="pricing__normal-price"
          style={{
            color: `rgba(${color}, 0.75)`,
          }}
        >
          <span>PLN</span>
          {normalPrice}{isRecurring &&<span className="duration">/mies</span>}
          <div
            className="pricing__normal-price__line"
            style={{
              backgroundColor: color,
            }}
          ></div>
        </p>
        <p className="pricing__discount-price">
          <span>PLN</span>
          {discountPrice}
          {isRecurring && (<span className="pricing__recurring">/mies</span>)}
        </p>
      </div>
      <div className="line" style={{
          backgroundColor: color,
          
      }}></div>

      {isPromoBlock && (
           <PromoBlock price={discountPrice} color={color} threeMPrice={threeMPrice} sixMPrice={sixMPrice}></PromoBlock>
      )}

   

      <button
        className="buy-btn"
        style={{
          backgroundColor: color,
        }}
        onClick={handleButtonClick}
      >
        Skontaktuj się
      </button>

      {showContactMe && (
        <>
          <ContactMe ref={contactMeRef} onCloseClick={handleCloseClick} className="contact-me" />
          <Overlay ref={overlayRef} onCloseClick={handleCloseClick}></Overlay>
        </>
      )}
    </div>
  );
};

export default BuyBlock;