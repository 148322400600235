import React, { forwardRef } from 'react';

import './Overlay.scss';

const Overlay = forwardRef(({onCloseClick}, ref ) => {
    return (
        <div className="overlay" ref={ref} onClick={onCloseClick}></div>
    )
})

export default Overlay;