import { useState, useEffect } from 'react';
import './ProductOptions.scss';

const ProductOptions = ({options, changeColor}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    // Set the first option in each row as the default selected option
    setSelectedOptions(options.map(() => 0));
  }, [options]);

  const handleOptionClick = (optionIndex, valueIndex) => {
    setSelectedOptions(prevOptions => {
      const newOptions = [...prevOptions];
      newOptions[optionIndex] = valueIndex;
      return newOptions;
    });
  
    if (options[optionIndex].name === 'Kolor' && options[optionIndex].values[valueIndex] === 'Biały') {
        changeColor('Biały');
    }
    if (options[optionIndex].name === 'Kolor' && options[optionIndex].values[valueIndex] === 'Czarny') {
        changeColor('Czarny');
    }
  };

  return (
    <div className="product-options">
      {options.map((option, optionIndex) => (
        <div className="product-options__row" key={optionIndex}>
          <div className="product-options__title">{option.name}</div>
          <div className="product-options__values">
            {option.values.map((value, valueIndex) => (
              <div
                className={`product-options__value ${selectedOptions[optionIndex] === valueIndex ? 'selected' : ''}`}
                key={valueIndex}
                onClick={() => handleOptionClick(optionIndex, valueIndex)}
              >
                <h5>{value}</h5>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductOptions;