import WaveBorder from "../components/WaveBorder";
import './OfferPage.scss';
import OfferBlock from "../components/OfferBlock";
import OfferBlob from "../components/OfferBlob";
import { useRef } from "react";
import offerData from "../data/offerData";

import ProductBlock from "../components/ProductBlock";

const OfferPage = () => {
    const blobRef = useRef(null);
  
    return (
      <>
        <WaveBorder rotated={true}></WaveBorder>
        <section className="offer-page" id="offer-page">
          <div className="offer-page__content">
            {
              offerData.oneTime.map((offer, index) => {


                if (index !== 2)
                return (
                  <OfferBlob key={index} {...offer} blobRef={blobRef} index={index}></OfferBlob>
                )
             
              })
            }
          </div>
          <ProductBlock price={79.99} previousPrice={89.99} title={'Koszulka Trenera Alana Słomińskiego'} subtitle={'Styl i Motywacja w Jednym'} desc={'Odkryj unikalną koszulkę trenera personalnego - Alana Słomińskiego. Inspirujący design łączy się z wygodą, podkreślając siłę transformacji. Gotowy na wyzwanie? Razem z Alanem do celu!'} info={'Koszulka dostępna we wszystkich rozmiarach, w kolorze czarnym i białym.'} isPromo={false}></ProductBlock>
          <ProductBlock price={149} previousPrice={179} title={'Trening Personalny'} subtitle={'Osiągnij Swoje Cele z Profesjonalnym Trenerem Osobistym'} desc={'Osiągnij swoje cele fitness dzięki moim treningom personalnym. Moje indywidualne podejście, motywacja i doświadczonie poprowadzą cię ku lepszej wersji siebie!'} isPromo={true}></ProductBlock>


          
          <div className="offer-page__offerts">
                <h1 className="offer-page__offerts__title main-titles__title">
                    Oferta
                </h1>
                <div className="offer-page__offerts__content">
                    <OfferBlock {...offerData.recurring[0]} ></OfferBlock>
                    <OfferBlock {...offerData.recurring[1]} ></OfferBlock>
                    <OfferBlock {...offerData.recurring[2]} ></OfferBlock>
                </div>
            </div>
        </section>
        <WaveBorder></WaveBorder>
      </>
    );
  };
  
  export default OfferPage;