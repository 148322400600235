import arrow from '../blobs/blob-helpPage-arrow.svg'
import arrow2 from '../blobs/blob-helpPage-arrow-2.svg'
import blob1 from '../blobs/blob-helpPage-content-1.svg'
import blob2 from '../blobs/blob-helpPage-content-2.svg'

import  { useAnimation } from '../components/technical/Context';
import "./HelpPage.scss";
import blobbigger1 from '../blobs/blob-help-page-bigger.svg';
import blobbigger2 from '../blobs/blob-help-page-bigger-2.svg';



const HelpPage = () =>{
    return(
        <section className="help-page">
            <div className="help-page__header">
                <img className='arrow arrow--left' src={arrow2} alt="arrow" />
                <div className="help-page__header__content">

                    <div className="main-titles centered">
                        <h1 className="main-titles__title ">
                            W czym mogę <br /> Ci pomóc
                        </h1>
                        <h2 className="main-titles__subtitle ">
                            Co mam do zaoferowania
                        </h2>
                    </div>
                  
                </div>
                <img className="arrow arrow--right" src={arrow} alt="arrow" />
            </div>
            <div className="help-page__content">
                <div className="help-page__content__left" style={{backgroundImage: `url(${blob1})`}}>
                    {/* <h1><img src={dot} alt="dot" />  osiągnieciu wymarzonej sylwetki</h1>
                    <h1><img src={dot} alt="dot" />  zgubieniu zbędnych kilogramów</h1>
                    <h1><img src={dot} alt="dot" />  przybraniu na masie mięśniowej</h1>
                    <h1><img src={dot} alt="dot" />  budowaniu siły i mocy</h1>
                    <h1><img src={dot} alt="dot" />  polepszeniu swojej wytrzymałości</h1>
                    <h1><img src={dot} alt="dot" />  zwiększeniu mobilności</h1>
                    <h1><img src={dot} alt="dot" />  poprawieniu techniki wykonywanych ćwiczeń</h1> */}
                </div>
                <div className="help-page__content__right" style={{backgroundImage: `url(${blob2})`}}>
                    {/* <h1><img src={dot} alt="dot" />  zminimalizowaniu skutków siedzącego trybu życia</h1>
                    <h1><img src={dot} alt="dot" />  polepszeniu postawy</h1>
                    <h1><img src={dot} alt="dot" />  polepszeniu zdrowia i samopoczucia</h1>
                    <h1><img src={dot} alt="dot" />  przygotowaniu się do egzaminu sprawnościowego</h1>
                    <h1><img src={dot} alt="dot" />  utrzymanie motywacji</h1> */}
                </div>
            </div>
        </section>
    )
};

export default HelpPage;