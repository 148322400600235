import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import WaveBorder from './WaveBorder';
import HamMenuWrapper from './HamMenu';
import logo from "../img/logo.svg";

import './Header.scss';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/userSlice';
const Header = () => {
  const [isHamVisible, setIsHamVisible] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth > 1200);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [menuClass, setMenuClass] = useState('ham-menu-wrapper hidden');
  const [hamLineClass, setHamLineClass] = useState('ham-btn__line');
  const hamLineRefs = [useRef(null), useRef(null), useRef(null)];
  const [activeNavLink, setActiveNavLink] = useState(null);
  const observerRef = useRef(null);

  // const user = useSelector(selectUser);

  // const [loggedIn, setLoggedIn] = useState(false);


  const scrollToSection = (sectionId) => {
    const section = document.querySelector('#' + sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    console.log(sectionId);
  };

  

  useEffect(() => {
    // if(user) {
    //   setLoggedIn(true);
    // }

   

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const sectionId = entry.target.id;
        if (entry.isIntersecting) {
          setActiveNavLink(sectionId);
        }
      });
    };
  
    const observerOptions = {
      threshold: 0.125,
      root: null,
      rootMargin: '0px',
    };
  
    // Delay the initialization of Intersection Observer
    const observerTimeout = setTimeout(() => {
      observerRef.current = new IntersectionObserver(handleIntersection, observerOptions);
  
      const sections = document.querySelectorAll('section');
      sections.forEach((section) => observerRef.current.observe(section));
    }, 100);
  
    return () => {
      clearTimeout(observerTimeout);
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth > 1200);
      setIsHamVisible(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const toggleClasses = () => {
      setMenuClass(isMenuClicked ? 'ham-menu-wrapper' : 'ham-menu-wrapper hidden');
      setHamLineClass(isMenuClicked ? 'ham-btn__line clicked' : 'ham-btn__line');
    };

    toggleClasses();

    const animation = requestAnimationFrame(toggleClasses);

    return () => {
      cancelAnimationFrame(animation);
    };
  }, [isMenuClicked]);

  return (
    <>
      <HamMenuWrapper className={menuClass}  onClick={()=> {
        setIsMenuClicked(false);
       
      }}/>

      <div className="header-wrapper">
        <header>
          <img className="logo" src={logo} alt="logo" />
          {innerWidth && (
            <nav>
              <Link 
                className={`navLink ${activeNavLink === 'home-page' ? 'active' : ''}`}
                to="#home-page"
                onClick={() => scrollToSection('home-page')}
              >
                Start
              </Link>
              <Link
                className={`navLink ${activeNavLink === 'about-me' ? 'active' : ''}`}
                to="#about-me"
                onClick={() => scrollToSection('about-me')}
              >
                O mnie
              </Link>
              <Link
                className={`navLink ${activeNavLink === 'reviews-page' ? 'active' : ''}`}
                to="#reviews-page"
                onClick={() => scrollToSection('reviews-page')}
              >
                Opinie
              </Link>
              <Link
                className={`navLink ${activeNavLink === 'glowups-page' ? 'active' : ''}`}
                to="#glowups-page"
                onClick={() => scrollToSection('glowups-page')}
              >
                Podopieczni
              </Link>
              <Link
                className={`navLink ${activeNavLink === 'offer-page' ? 'active' : ''}`}
                to="#offer-page"
                onClick={() => scrollToSection('offer-page')}
              >
                Oferta
              </Link>
{/* 
              {loggedIn ? <Link to="/account">
                <span>Konto</span>
              </Link> : <Link to="/login">
                <span>Zaloguj</span>
              </Link> }  */}
            </nav>
          )}
        </header>
        <WaveBorder rotated={false} />
      </div>

      {!innerWidth || isMenuClicked ? (
        <div className="ham-btn" onClick={() => setIsMenuClicked(!isMenuClicked)}>
          <div className={hamLineClass} ref={hamLineRefs[0]}></div>
          <div className={hamLineClass} ref={hamLineRefs[1]}></div>
          <div className={hamLineClass} ref={hamLineRefs[2]}></div>
        </div>
      ) : null}


      {isMenuClicked && <div className="ham-overlay" onClick={() => setIsMenuClicked(false)}></div>
      
      }
    </>
  );
};

export default Header;
