import cloudBlob from '../blobs/blob-glowup.svg';
import scalesIcon from '../icons/scales.svg';
import { forwardRef, useRef, useState, useEffect } from 'react';

import './Glowup.scss';


 const Glowup = forwardRef(({name,desc,wghChange, time, imgUrl1, imgUrl2}, ref) => {

     const imageRef = useRef(null);
     const [imagesWidth, setImagesWidth] = useState(0);

    const weightChange = (change) => {
        if (change  >  0) {
            return `+${change}`
        }
        else return change;
    }


    // useEffect(()=> {

    //     const image = imageRef.current;
    //     const imageWidth = image.clientWidth;
    //     setImagesWidth(imageWidth);
    //     console.log(`Image ${imageWidth}`);

    // }, [imagesWidth, imageRef])

    return (


        
        <div className="glowup" ref={ref}>
            
            <div className="glowup__header">
                <img src={imgUrl1} alt="ada" />
                <img src={imgUrl2} alt="add" />
            </div>

            <div className="glowup__content">
                <h1 className="glowup__content__header">
                    {name}
                </h1>
                {/* <p className="glowup__content__body"><span className="quote quote--top">"</span>{desc}<span className="quote quote--bottom">"</span></p> */}
                <div className="glowup__content__footer">
                    <img src={scalesIcon} alt="dd" ref={imageRef}/>
                    <p>{weightChange(wghChange)}kg w {time} miesiące</p>
                </div>
            </div>
        </div>
        
    )

});

export default Glowup;