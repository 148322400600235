import React from 'react';
import phoneImg from '../img/iphone-mockup.png';

import { reviewsImgs } from './technical/reviewsImgs';

import{reviewsDataByDate, reviewsData} from '../data/reviewsData';

import { useEffect } from 'react';

import "./Phone.scss";

const Phone = () => {


  // useEffect(()=> {
  //   console.log(reviewsData.map((review, index) => {
  //     return review.post;
  //   }))
  // })


  const renderedPosts = reviewsData.map((review) => {
    return review.post;
}).join('');

  const iframeStyle = `
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow-x: hidden;
    }

    body {
        
        
        background-color: black;
        overflow: auto;
        overflow-scrolling: touch;
        -webkit-overflow-scrolling: touch;
        transform: translate3d(0, 0, 0);
        //pointer-events: none;
        user-select: none;
        //cursor: grab;
        -webkit-user-drag: none;
        position: relative;
        width:100%;
        overflow-x: hidden;
        background-color: white;
        //padding-right: 10px;
        display:flex;
        flex-direction: column;
   
    }
    
    .fb-post {
      width:100vw !important;
      overflow: hidden;
      overscroll-behavior-y: contain;
      
    }

    #text {
      display: none !important;
      visibility: hidden !important;
      color: white;
    }

    img {
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
        display: block;
        transform: translate3d(0, 0, 0);
        cursor: grab;
        overflow:none;
    }

    #fb-root {
      display:flex;
      padding-right: 10px;
    }
   
    ::-webkit-scrollbar {
      width: 13px;
      height: 18px;
    }
    
    ::-webkit-scrollbar-thumb {
      background-color: rgb(200, 200, 200);
      border-radius: 7px;
    }
    
    ::-webkit-scrollbar-track {
      background-color: white;
    }
    
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  `;

  return (
    <div className="phone">
      <img src={phoneImg} alt="" />

      <div className="link">
        <p>
          www.alanslominski.pl
        </p>
      </div>

      <iframe
        width="100%"
        height="100%"
        srcDoc={`
          <style>
            ${iframeStyle}
          </style>
          <script>
            let isDragging = false;
            let startX, startY, startScrollLeft, startScrollTop;

            document.addEventListener('mousedown', function(event) {
              isDragging = true;
              startX = event.clientX;
              startY = event.clientY;
              startScrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
              startScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            });

            document.addEventListener('mouseup', function() {
              isDragging = false;
            });

            document.addEventListener('mousemove', function(event) {

              console.log('event.clientX: ', event.clientX);
              if (!isDragging) return;

              const deltaX = startX - event.clientX;
              const deltaY = startY - event.clientY;

              window.scrollTo(startScrollLeft + deltaX, startScrollTop + deltaY * 1.6);
              return true;
            });
          </script>
          <script async defer crossorigin="anonymous" src="https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v17.0" nonce="FjPYsgul"></script>

       
         

            ${renderedPosts}
        
       
        `}
        title="reviews"
      ></iframe>
    </div>
  );
}




export default Phone;
